<template>
  <ul class="en-order-list">


     <li class="order-item">

      <div class="item-head m-padding" v-if="status == 0">
        <div class="merchant">
          <img src="@/assets/order/441589786465_.pic_hd@2x.png" alt="merchant">
          <p class="van-ellipsis">Mooby自营<span>(共2件)</span></p>
        </div>
      </div>

      <div class="item-head m-padding" v-else-if="status == 1">
        <div class="title">
          <p>服务单号：83998929</p>
          <p><img src="@/assets/aftersale/return.png" alt=""><span>退货</span></p>
        </div>
      </div>

      <div class="item-head m-padding" v-else>
        <div class="title">
          <p>服务单号：83998929</p>
          <p><img src="@/assets/aftersale/exchange.png" alt=""><span>换货</span></p>
          <p v-if="type == 1"><img src="@/assets/aftersale/refund.png" alt=""><span>退款</span></p>
        </div>
      </div>

      <div  class="item-content van-hairline--top van-hairline--bottom">
        <div class="content-left"><img src="@/assets/fork-imgs/156740814737337x3vk.png" alt="goods"></div>
        <div class="content-right">
          <p class="goods-title van-ellipsis">迷你萌熊加湿器小型喷雾真是好用谁用谁知道</p>
          <div class="goods-info">
            <span class="goods-sku">雾霾蓝-机械版</span>
            <span class="goods-status">x1</span>
          </div>
          <div class="goods-price">$9.95</div>
        </div>
      </div>

      <div class="item-foot m-padding" v-if="status == 0">
        <div class="btns">
          <router-link to="/en/aftersale/select" class="btn-acitve">申请售后</router-link>
        </div>
      </div>

      <div class="item-foot m-padding" v-else>
        <router-link to="/en/aftersale/detail/1" class="btns-disable">
          <span class="bth-tips">售后服务已关闭</span>
          <img src="@/assets/order/icon-fanhui@2x.png" alt="">
        </router-link>
      </div>

    </li>

    <li class="order-item">

      <div class="item-head m-padding"  v-if="status == 0">
        <div class="merchant">
          <img src="@/assets/order/441589786465_.pic_hd@2x.png" alt="merchant">
          <p class="van-ellipsis">Mooby自营<span>(共2件)</span></p>
        </div>
      </div>

      <div class="item-head m-padding" v-else-if="status == 1">
        <div class="title">
          <p>服务单号：83998929</p>
          <p><img src="@/assets/aftersale/return.png" alt=""><span>退货</span></p>
        </div>
      </div>

      <div class="item-head m-padding" v-else>
        <div class="title">
          <p>服务单号：83998929</p>
          <p><img src="@/assets/aftersale/refund.png" alt=""><span>退款</span></p>
        </div>
      </div>

      <div v-for="i in 6"  :key="i" class="item-content van-hairline--top van-hairline--bottom">
        <div class="content-left"><img src="@/assets/fork-imgs/156740814737337x3vk.png" alt="goods"></div>
        <div class="content-right">
          <p class="goods-title van-ellipsis">迷你萌熊加湿器小型喷雾真是好用谁用谁知道</p>
          <div class="goods-info">
            <span class="goods-sku">雾霾蓝-机械版</span>
            <span class="goods-status">x1</span>
          </div>
          <div class="goods-price">$9.95</div>
        </div>
      </div>

      <div class="item-foot m-padding">
        <div class="btns-disable">
          <span class="bth-tips">该订单已超过售后期</span>
          <span class="btn">申请售后</span>
        </div>
      </div>

    </li>

  </ul>
</template>

<script>
export default {
  name: "OrderList",
  props: {
    list: {
      type: Array,
    },
    status: {
      type: Number,
      default: 0
    }
  },
  data(){
    return {
      type: 0,
    }
  },



  methods: {
    handleBack(){
      this.$router.go(-1)
    },
    onPay(){
      console.log('支付')
    }

  },
}
</script>

<style lang="less" scoped>
.en-order-list {
  width: 100%;
  
  z-index: 9;
  .order-item {
    background-color: #fff;
    margin-top: 10px;
    box-shadow: 0px 10px 10px 0 rgba(217, 217, 217, 0.15);
    .item-head {
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .merchant {
        height: 44px;
        display: flex;
        align-items: center;
        flex: 1;
        &>img {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
        &>p {
          font-size:16px;
          font-weight:500;
          height: 44px;
          line-height: 44px;
          width: 100%;
          color:rgba(12,2,29,1);
        }
      }
      .title {
        height: 44px;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        >p:nth-of-type(1){
          font-size:14px;
          font-weight:400;
          color:rgba(102,102,102,1);
          line-height:20px;
        }
        >p:nth-of-type(2){
          font-size:14px;
          font-weight:500;
          color:#000;
          line-height:20px;
          height: 20px;
          display: flex;
          align-items: center;
          >img {
            width: 18px;
            height: 18px;
            margin-right: 5px;
          }
        }
      }
    }
    .item-content {
      margin-left: 16px;
      height: 103px;
      display: flex;
      align-items: center;
      .content-left {
        width: 80px;
        height: 80px;
        margin-right: 12px;
        border-radius: 5px;
        &>img {
          width: 80px;
          height: 80px;
        }
      }
      .content-right {
        flex: 1;
        height: 80px;
        .goods-title{
          height: 20px;
          line-height: 20px;
          padding-right: 50px;
          width: 245px;
          font-size:16px;
          font-weight:400;
          color:rgba(51,51,51,1);
        }
        .goods-info {
          width: 100%;
          padding-right: 16px;
          display: flex;
          align-content: center;
          justify-content: space-between;
          margin-top: 4px;
          .goods-sku {
            height: auto;
            display: inline-block;
            background:rgba(245,245,245,1);
            border-radius:3px;
            padding: 0 5px;
            font-size:12px;
            font-weight:400;
            color:rgba(102,102,102,1);
            height: 18px;
            line-height: 18px;
          }
          .goods-status {
            height: 26px;
            line-height: 26px;
            font-size:14px;
            font-weight:400;
            color:#333333;
          }
        }
        .goods-price {
          font-size:14px;
          font-weight:500;
          color:rgba(51,51,51,1);
          line-height:18px;
          margin-top: 2px;
        }
      }
    }
    .item-foot {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .price {
        font-size:14px;
        font-weight:400;
        color:rgba(51,51,51,1);
        &>span:nth-of-type(1){
          font-size: 10px;
          color: #ED2A24;
        }
        &>span:nth-of-type(2){
          color: #ED2A24;
        }
      }
      .btns {
        .btn-acitve {
          padding: 5px 12px;
          font-size: 14px;
          color: #ED2A24;
          border: 1px solid #ED2A24;
          border-radius: 35px;
        }
        .btn {
          padding: 5px 12px;
          font-size: 14px;
          color: #999;
          border: 1px solid #999;
          border-radius: 35px;
        }
      }
      .btns-disable {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .bth-tips {
          font-size:12px;
          font-weight:400;
          color:rgba(102,102,102,1);
          line-height:17px;
        }
        >img {
          width: 16px;
          height: 16px;
          transform: rotate(180deg);
        }
      }
    }
  }
  
}
</style>