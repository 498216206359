<template>
  <section class="m-aftersale-list m-scroll">
    <!-- 头部导航 -->
    <m-header title="退换/售后"></m-header>

    <van-tabs  animated title-active-color="#ED2A24" line-width="32px" line-height="2px" :border="false" sticky  swipeable :offset-top="top">

        <van-tab title="售后申请">

          <div class="search-box">
            <div class="input-box">
              <img src="@/assets/index/Search@2x.png" alt="Search">
              <input v-model="value" type="text" placeholder="请输入关键词">
            </div>
            <img src="@/assets/order/icon_shaixuan@2x.png" alt="" @click="filter = true">
          </div>

          <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
               <after-sale :status="0"></after-sale>
            </van-list>
          </van-pull-refresh>
          
        </van-tab>  

        <van-tab title="处理中">
          <div class="search-box">
            <div class="input-box">
              <img src="@/assets/index/Search@2x.png" alt="Search">
              <input v-model="value" type="text" placeholder="请输入关键词">
            </div>
            <img src="@/assets/order/icon_shaixuan@2x.png" alt="">
          </div>
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
               <after-sale :status="1"></after-sale>
            </van-list>
          </van-pull-refresh>
        </van-tab>

        <van-tab title="申请记录">
          <div class="search-box">
            <div class="input-box">
              <img src="@/assets/index/Search@2x.png" alt="Search">
              <input v-model="value" type="text" placeholder="请输入关键词">
            </div>
            <img src="@/assets/order/icon_shaixuan@2x.png" alt="">
          </div>
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
               <after-sale :status="2"></after-sale>
            </van-list>
          </van-pull-refresh>
        </van-tab>

    </van-tabs>





    <!-- 筛选弹窗 -->
    <van-popup v-model="filter" get-container="#app"  position="right" close-on-popstate class="filter-order" >
      <p class="filter-title van-hairline--bottom">时间筛选</p>
      <p class="filter-item"><span class="item" :class="{'filter-active':month == 1}" @click="handleMonth(1)">最近一个月</span></p>
      <p class="filter-item"><span class="item" :class="{'filter-active':month == 3}" @click="handleMonth(3)">最近三个月</span></p>
      <p class="filter-item"><span class="item" :class="{'filter-active':month == 6}" @click="handleMonth(6)">最近六个月</span></p>
    </van-popup>


  </section>
</template>

<script>
import MHeader   from '@/components/en/m-header.vue'
import AfterSale from '@/components/en/aftersale-list.vue'
export default {
  name:'AfterSaleList',
  components:{ MHeader, AfterSale },
  data(){
    return {
      value:'',
      isLoading:false,
      loading:false,
      finished:false,
      num:2,
      list:2,
      filter:false,
      month: 1,
    }
  },


  mounted(){

  },

  methods:{

    onLoad(){

    },

    // 时间 筛选
    handleMonth(month){
      this.month = month
      let timer = setTimeout( () => {
        this.filter = false
        clearTimeout(timer)
      }, 300)
    },

    onRefresh() {
      setTimeout(() => {
        this.$notify({
          type: 'success',
          message: '刷新成功' 
        })
        this.isLoading = false;
        this.count++;
      }, 1000);
    },
  },
  computed: {
    top: {
      set(){
        return 44
      },
      get(){
        let clientWidth = document.body.clientWidth
        return 44/375*clientWidth
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import './list.less';
</style>